var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Properties")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Properties","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
                _vm.currentUserRole.name == 'VH Superadmin' ||
                _vm.currentUserRole.name == 'Manager'
              )?_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"depressed":"","color":"accent"},on:{"click":function($event){return _vm.$refs.PropertyDialog.open()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Add Manual Property")])]),(
            _vm.currentUserRole.name == 'VH Superadmin' ||
            _vm.currentUserRole.name == 'Manager'
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"depressed":"","light":"","to":{ name: _vm.$route.name + '-archived' }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-archive")])],1)]}}],null,false,3165519344)},[_c('span',[_vm._v("View Archived Properties")])]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.tableHeaders,"items":_vm.properties,"no-data-text":"No Properties found"},scopedSlots:_vm._u([{key:"item.visibility",fn:function({ item }){return [_c('v-switch',{on:{"click":function($event){return _vm.openSwitch(item)}},model:{value:(item.visibility),callback:function ($$v) {_vm.$set(item, "visibility", $$v)},expression:"item.visibility"}})]}},{key:"item.actions",fn:function({ item }){return [(
            _vm.currentUserRole.name == 'VH Superadmin' ||
            _vm.currentUserRole.name == 'Manager'
          )?_c('div',[(item.status == 'Manual' || item.status == 'Old')?_c('v-btn',{staticClass:"mr-2",attrs:{"bottom":"","x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.openDelete(item)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1):_vm._e()],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                name: 'module-easylets-properties-individual',
                params: { propertyId: item.property_id },
              }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])]}},{key:"item.available_date",fn:function({ item }){return [_vm._v(" "+_vm._s(item.available_date ? _vm.formatDate(item.available_date) : "")+" ")]}}])})],1),_c('Property-dialog',{ref:"PropertyDialog"}),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.deleteProperty.dialog),callback:function ($$v) {_vm.$set(_vm.deleteProperty, "dialog", $$v)},expression:"deleteProperty.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Archive Property")]),_c('v-card-text',[_vm._v("Are you sure you wish to archive "+_vm._s(_vm.deleteProperty.property.full_address)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.resetDelete}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.deleteProperty.loading},on:{"click":_vm.saveDelete}},[_vm._v("Yes")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }