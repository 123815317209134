<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Properties</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Properties"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="
                  currentUserRole.name == 'VH Superadmin' ||
                  currentUserRole.name == 'Manager'
                "
                depressed
                color="accent"
                class="mr-2"
                v-on="on"
                @click="$refs.PropertyDialog.open()"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Manual Property</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              currentUserRole.name == 'VH Superadmin' ||
              currentUserRole.name == 'Manager'
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                light
                v-on="on"
                :to="{ name: $route.name + '-archived' }"
              >
                <v-icon small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>View Archived Properties</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="properties"
        class="mt-4"
        no-data-text="No Properties found"
      >
        <template v-slot:item.visibility="{ item }">
          <v-switch
            v-model="item.visibility"
            @click="openSwitch(item)"
          ></v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <div
            v-if="
              currentUserRole.name == 'VH Superadmin' ||
              currentUserRole.name == 'Manager'
            "
          >
            <v-btn
              v-if="item.status == 'Manual' || item.status == 'Old'"
              bottom
              x-small
              depressed
              class="mr-2"
              color="red lighten-4 red--text"
              @click="openDelete(item)"
            >
              <v-icon x-small>mdi-archive</v-icon>
            </v-btn>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                :to="{
                  name: 'module-easylets-properties-individual',
                  params: { propertyId: item.property_id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>

        <template v-slot:item.available_date="{ item }">
          {{ item.available_date ? formatDate(item.available_date) : "" }}
        </template>

        <!-- <template v-slot:item.type="{ item }">
          {{ item.tco == true ? "TCO" : "" }}
          {{ item.hmo_listing == true ? "HMO" : "" }}
        </template> -->
      </v-data-table>
    </v-container>
    <Property-dialog ref="PropertyDialog" />

    <v-dialog v-model="deleteProperty.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Property</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteProperty.property.full_address }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteProperty.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import PropertyDialog from "./components/PropertyDialog.vue";

export default {
  components: {
    PropertyDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Address", value: "full_address" },
        { text: "Applications", value: "applications_in_progress" },
        { text: "Groups", value: "interested_groups" },
        {
          text: "Property Manager",
          value: "property_manager",
        },

        { text: "Monthly Rent", value: "monthly_rent" },
        { text: "Available from", value: "available_date" },
        { text: "Visibility", value: "visibility" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status" },

        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteProperty: {
        dialog: false,
        property: {},
        loading: false,
      },
      fields: {
        visibility: true,
      },
    };
  },

  computed: {
    currentUserRole() {
      let appId = this.$route.params.id;
      let roles = this.$store.getters.roles;

      roles = roles.filter(function (app) {
        return app.app_id == appId;
      });

      return roles[0];
    },

    properties() {
      let properties = this.$store.getters["easylets/propertiesStore/all"];

      properties.map((item) => {
        let test = "";

        if (item.tco == true) {
          test = "TCO";
        } else if (item.hmo_listing == true) {
          test = "HMO";
        }

        item.type = test;
      });

      if (this.searchTerm !== "") {
        properties = properties.filter((c) => {
          const fullAddress = c.full_address.toLowerCase();
          const propertyMenager =
            c.property_manager !== null ? c.property_manager.toLowerCase() : "";

          const searchTerm = this.searchTerm.toLowerCase();

          return (
            fullAddress.includes(searchTerm) ||
            propertyMenager.includes(searchTerm)
          );
        });
      }

      return properties;
    },
  },

  methods: {
    formatDate(dateString) {
      return moment(dateString).format("DD/MM/YYYY");
    },

    rowClick: function () {
      /*
      this.$router.push({
        name: "module-easylets-properties-individual",
        params: { propertyId: item.property_id },
      });*/
    },

    openDelete(property) {
      this.deleteProperty.property = property;
      this.deleteProperty.dialog = true;
    },

    openSwitch(property) {
      this.loading = true;
      this.errors = {};

      this.fields.visibility = property.visibility ? 1 : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        propertyId: property.property_id,
      };

      this.$store
        .dispatch("easylets/propertiesStore/savePropertyVisible", payload)
        .then(() => {})
        .catch();
    },

    resetDelete() {
      this.deleteProperty.dialog = false;
      this.deleteProperty.property = {};
      this.deleteProperty.loading = false;
    },

    saveDelete() {
      this.deleteProperty.loading = true;

      this.$store
        .dispatch("easylets/propertiesStore/deleteProperty", {
          appId: this.$route.params.id,
          propertyId: this.deleteProperty.property.property_id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteProperty.loading = false;
        });
    },
  },
};
</script>
