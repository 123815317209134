<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Property
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="Full Address *"
            v-model="fields.full_address"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('full_address')"
            :error-messages="errors['full_address']"
          ></v-text-field>
          <v-text-field
            label="Monthly Rent *"
            v-model="fields.monthly_rent"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('monthly_rent')"
            :error-messages="errors['monthly_rent']"
          ></v-text-field>
          <v-text-field
            label="Property Manager *"
            v-model="fields.property_manager"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_manager')"
            :error-messages="errors['property_manager']"
          ></v-text-field>

          <v-switch
            label="Visibility"
            v-model="fields.visibility"
            inset
            :error="errors.hasOwnProperty('visibility')"
            :error-messages="errors['visibility']"
          ></v-switch>

          <v-switch
            label="HMO"
            v-model="fields.hmo_listing"
            inset
            :error="errors.hasOwnProperty('hmo_listing')"
            :error-messages="errors['hmo_listing']"
            @change="changeStatus('hmo')"
          ></v-switch>

          <v-switch
            label="TCO"
            v-model="fields.tco"
            inset
            :error="errors.hasOwnProperty('tco')"
            :error-messages="errors['tco']"
            @change="changeStatus('tco')"
          ></v-switch>

          <v-menu
            v-model="startDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Available From *"
                v-model="availableFromFormatted"
                prepend-inner-icon="mdi-calendar"
                outlined
                background-color="white"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields.available_date"
              @input="startDatePicker = false"
              first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      customer: {},
      startDatePicker: false,

      fields: {
        property_manager: null,
        available_from: null,
        full_address: null,
        monthly_rent: null,
        status: "Manual",
        tco: false,
        hmo_listing: false,
        visibility: true,
      },

      errors: {},
    };
  },

  computed: {
    availableFromFormatted() {
      return this.formatDate(this.fields.available_date);
    },
  },

  methods: {
    changeStatus(item) {
      if (item == "hmo") {
        let value = this.fields.hmo_listing;

        if (value) {
          this.fields.tco = false;
        }
      }

      if (item == "tco") {
        let value = this.fields.tco;

        if (value) {
          this.fields.hmo_listing = false;
        }
      }
    },

    open(property = null) {
      if (property !== null) {
        this.property = property;
        this.fields.property_manager = property.property_manager;
        this.fields.full_address = property.full_address;
        this.fields.monthly_rent = property.monthly_rent;
        this.fields.available_from = property.available_from;
        this.fields.visibility = property.visibility;
        this.fields.tco = property.tco;
        this.fields.hmo_listing = property.hmo_listing;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.propertyId = this.property.id;
      }

      this.$store
        .dispatch("easylets/propertiesStore/saveProperty", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.customer = {};
      this.fields = {
        property_manager: null,
        available_from: null,
        full_address: null,
        monthly_rent: null,
        status: "Manual",
        visibility: true,
        tco: false,
        hmo_listing: false,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
